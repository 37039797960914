.memberships__container{
    display: grid;
    grid-template-columns: repeat(2, 550px);
    column-gap: 3rem;
    justify-content: center;
    align-items: center;
}

.memberships__subtitle{
    text-align: center;
    display: block;
    font-size: var(--small-font-size);
    width: 100%;
    padding: 20px 80px 20px 80px;
    margin-bottom: 2rem;

}

.memberships__content{
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 3rem 3rem;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.memberships__button{
    padding: 0.5rem 2rem;
    border-radius: 0;
    margin-top: 20px;
    background-color: goldenrod;
    color: white;
    font-weight: var(--font-semi-bold);
}

.memberships__img{
    width: 80%;
    aspect-ratio: 1 / 1;
}

.memberships__title{
    font-size: 1.2rem;
    font-weight: var(--font-semi-bold);
    text-align: center;
    margin-bottom: var(--mb-1-5);
    
}

.memberships__box{
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
    text-align: center;
}

.memberships__group{
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
}

.memberships__data{
    display: flex;
    column-gap: 0.5rem;
}

.skills .bx-badge-check{
    font-size: 1rem;
    color: var(--title-color);
}

.memberships__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
}

.memberships__level{
    font-size: var(--tiny-font-size);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .memberships__container{
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 576px) {
    .memberships__container{
        grid-template-columns: 1fr;
    }

    .memberships__content{
        padding: 1.5rem;
    }

    .memberships__subtitle{
        text-align: center;
        display: block;
        font-size: var(--small-font-size);
        width: 100%;
        padding: 10px 40px 10px 40px;
        margin-bottom: 2rem;
    
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .memberships__box{
        column-gap: 1.25rem;
    }

    .memberships__name{
        font-size: var(--small-font-size);
    }
  }
  