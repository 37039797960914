.waiver__container{
    align-items: center;
    justify-content: center;;
    display: flex;
    margin-top: 20px;
}

.waiver__content{
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--container-color);
    padding: 2rem 2.5rem  2rem 2.5rem;
    width: 80%;
    text-align: center;
}

.waiver__img{
    width: 220px;
    border-radius: 1.5rem;
    margin-bottom: 20px;
}


.waiver__title{
    font-size: 2rem;
    margin-bottom: var(--mb-1);
    font-weight: var(--font-semi-bold);
}

.waiver__button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
    margin-top: 30px;
}

.waiver__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.waiver__button:hover .waiver__button-icon{
    transform: translateX(0.25rem)
}

.waiver__modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    /*opacity: 1;
    visibility: visible;*/
    opacity: 0;
    visibility: hidden;
}

.waiver__modal-content{
    width: 900px;
    position: relative;
    background-color: var(--container-color);
    padding: 3rem;
    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.waiver__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.waiver__modal-title,
.waiver__modal-description{
    text-align: center;
}

.waiver__modal-title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.waiver__modal-description{
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    
}

.waiver__modal-services{
    row-gap: 0.75rem;
}

.waiver__modal-service{
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

.waiver__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}

.waiver__modal-info{
    font-size: var(--small-font-size);
}

.active-modal{
    opacity: 1;
    visibility: visible;
}

.two_container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.waiver__form{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 0;
    margin-top: 40px;
}

.waiver__form-div{
    position: relative;
    margin-bottom: var(--mb-2);
}

.waiver__form-input{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid white;
    background: var(--container-color);
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1rem;
    z-index: 1;
    color: white;
    
}

.waiver__form-tag{
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: #242526;
    z-index: 10;
}

.waiver__form-area{
    height: 11rem;
}

.waiver__form-area textarea{
    resize: none;
}

.buttons{
    display: inline-block;
  background-color: black;
  color: var(--text-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.button {
    display: inline-block;
    background-color: var(--container-color);
    color: var(--text-color);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
  }
  
  .buttons:hover {
    background-color: rgb(20, 20, 20);
  }
  
  .buttons__icon {
    margin-left: var(--mb-0-5);
  }
  
  .buttons--flex {
    display: inline-flex;
    align-items: center;
  }

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .waiver__container{
        
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .waiver__container{
        width: 100%;
    }

    .waiver__content{
        padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    }

    .waiver__icon{
        font-size: 1.5rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .waiver__content{
        width: 90%;
    }
    .waiver__modal-description{
        padding: 4.5rem 1.5rem 2.5rem;
    }

    .waiver__modal-description{
        padding: 0;
    }

    .waiver__form{
        width: 100%;
    }

    .two_container{
        display: grid;
        grid-template-columns: 100%;
        gap: 0;
    }
    

  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .waiver__container{
        grid-template-columns: max-content;
    }

    .waiver__content{
        padding-right: 6rem;
    }
  }