#home{
  background-image: url(/src/assets/matlock-header.jpg);
  background-size: cover;
  
}

.home__container{
    row-gap: 1rem;
    max-width: 968px;
}

.home__content{
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 3rem;
    padding-bottom: 3rem;
    column-gap: 0.5rem;
    align-items: center;
}

.home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon{
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__title{
    font-size: 5rem;
    margin-bottom: var(--mb-0-25);
    font-weight: var(--font-semi-bold)
}

.home__subtitle{
    position: relative;
    font-size: 1.5rem;
    padding-left: 5.4rem;
    font-weight: 600;
    margin-bottom: var(--mb-1);
}

.home__subtitle::before{
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
}

.home_social-icon:hover{
    color: var(--title-color-dark);
}

.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.home__img{
    background: url(../../assets//profile.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 400px;
    height: 400px;

}

.home__scroll{
    margin-left: 7.5rem;
    margin-top: -2rem;
}

.wheel{
    animation: scroll 2s ease infinite;
}

@keyframes scroll{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(3.75rem);
    }
}

.home__scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow{
    font-size: 1.25rem;
    color: var(--title-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .home__content{
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home__hand{
    width: 26px;
    height: 26px;
  }

  .home__subtitle{
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before{
    width: 42px;
    top: 0.8rem;
  }

  .home__description{
    max-width: initial;
    margin-bottom: var(--mb-2-5)

  }

  .home__img{
    width: 250px;
    height: 250px;
  }

  .home__scroll{
    margin-left: 7.5rem;
  }

  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
  .home__content{
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
  }

  .home__img{
    order: initial;
    justify-self: initial;
  }

  .home__data{
    grid-column: 1/3;
  }

  .home__img{
    width: 200px;
    height: 200px;
  }

  .home__scroll{
    display: none;
  }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
  .home__img{
    width: 180px;
    height: 180px;
  }

  .home__hand{
    width: 22px;
    height: 22px;
  }
  }
  